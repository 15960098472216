import React from "react";
import Image from "../Hacks/Image";
import { Link } from "gatsby";
import { Fade } from "react-awesome-reveal";

export default class BlogCard extends React.Component {
  render() {
    const {
      featured_media,
      title,
      date,
      excerpt,
      slug,
      tag,
      inTagPage,
      readtime,
    } = this.props;

    return (
      <div className="rounded overflow-hidden position-relative card h-100">
        <Fade>
          <>
            <div className="card-header p-0 position-relative">
              <div className="position-absolute text-primary right-0 small z-index-100 pl-2 pr-3 py-1 font-weight-bold bg-white rounded-bottom-right shadow-lg opacity-80">
                <i className="far fa-clock mr-1 small" />
                {readtime}min read
              </div>
              {featured_media && (
                <Image
                  image={featured_media}
                  className="w-100 bg-light floaty card-journal__image"
                  alt={title}
                />
              )}
              {!featured_media && (
                <div className="card-journal__image bg-primary" />
              )}
            </div>

            <div className="card-body pb-5">
              <div className={`small text-right`}>{date}</div>
              {!inTagPage && tag && (
                <div className="h5 text-dark card-title text-black mb-0 small">
                  {tag.length > 1 ? tag[1] : tag}
                </div>
              )}
              <Link className="text-primary small stretched-link" to={slug}>
                <h2 className="h4 font-family-display card-title">
                  <span>{title}</span>
                </h2>
              </Link>
              <p className="card-text mb-4">{excerpt}</p>
            </div>
          </>
        </Fade>
      </div>
    );
  }
}
